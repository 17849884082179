// @ts-nocheck
import { useEffect, useState } from "react"
import { Routing } from "../../../utils/types"
import { Layer, Source } from "react-map-gl"
import { FeatureCollection } from "geojson"
import _ from "lodash"

export default function DisplayPath({ routing }: { routing: Routing }) {
  const [geoJson, setGeoJson] = useState<FeatureCollection | undefined>(
    undefined
  )
  const [isCurrentInData, setIsCurrentInData] = useState(false)
  useEffect(() => {
    let deepCopy: any = _.cloneDeep(routing.geo_json_path)
    deepCopy.features = deepCopy.features.map((feature: any) => {
      if (feature.properties) {
        feature.properties.sog = feature.properties.sog.toFixed(1) + " kts"
        feature.properties.twa = "twa " + feature.properties.twa.toFixed() + "°"
        feature.properties.offsetX =
          Math.sin((feature.properties.wind_direction * 3.14) / 180) * 7
        feature.properties.offsetY =
          -Math.cos((feature.properties.wind_direction * 3.14) / 180) * 7
        if (feature.properties.current_speed !== undefined) {
          setIsCurrentInData(true)
          feature.properties.current_speed =
            feature.properties.current_speed.toFixed(1) + " kts"
          feature.properties.current_offsetX =
            Math.sin((feature.properties.current_heading * 3.14) / 180) * 7
          feature.properties.current_offsetY =
            -Math.cos((feature.properties.current_heading * 3.14) / 180) * 7
        }
      }
      return feature
    })
    setGeoJson(deepCopy)
  }, [routing.geo_json_path])

  return (
    <>
      {geoJson && (
        <>
          <Source id="source-path" type="geojson" data={geoJson}>
            <Layer
              id="path"
              type="line"
              source="source-path"
              paint={{
                "line-color": [
                  "match",
                  ["get", "is_motor"],
                  1,
                  "yellow",
                  0,
                  "white",
                  "white",
                ],
                "line-width": 2,
                "line-dasharray": [
                  "match",
                  ["get", "is_motor"],
                  1,
                  [2, 2],
                  0,
                  [1],
                  [1],
                ],
              }}
            />
            <Layer
              id="wind-symbol-sog"
              type="symbol"
              source="source-path"
              layout={{
                "icon-image": [
                  "match",
                  ["get", "is_motor"],
                  1,
                  "propeller-icon",
                  0,
                  "",
                  "",
                ],
                "icon-anchor": "right",
                "icon-offset": [-30, 16],
                "icon-size": 0.7,
                "text-field": ["get", "sog"],
                "text-size": 14,
                "symbol-placement": "line-center",
                "text-allow-overlap": true,
                // "text-ignore-placement": true,
                "text-offset": [0, 1],
              }}
              paint={{
                "text-color": "white",
              }}
            />
            <Layer
              id="wind-icon"
              type="symbol"
              source="source-path"
              layout={{
                "icon-image": "wind-icon",
                "text-field": [
                  "concat",
                  [
                    "to-string",
                    ["/", ["round", ["*", ["get", "wind_speed"], 10]], 10],
                  ],
                  " kts",
                ],
                "text-size": 14,
                "text-offset": [
                  ["get", "offsetX"],
                  ["get", "offsetY"],
                ],
                "icon-allow-overlap": true,
                "icon-size": 0.07,
                "icon-anchor": "bottom",
                "icon-rotate": ["get", "wind_direction"],
                "icon-rotation-alignment": "map",
                "icon-offset": [0, 0],
              }}
              paint={{
                "text-color": "white",
              }}
            />
            {isCurrentInData && (
              <Layer
                id="current-icon"
                type="symbol"
                source="source-path"
                layout={{
                  "icon-image": "wind-icon",
                  "text-field": [
                    "concat",
                    [
                      "to-string",
                      ["/", ["round", ["*", ["get", "current_speed"], 10]], 10],
                    ],
                    " kts",
                  ],
                  "text-size": 14,
                  "text-offset": [
                    ["get", "current_offsetX"],
                    ["get", "current_offsetY"],
                  ],
                  "icon-allow-overlap": true,
                  "icon-size": 0.07,
                  "icon-anchor": "bottom",
                  "icon-rotate": ["get", "current_heading"],
                  "icon-rotation-alignment": "map",
                  "icon-offset": [0, 0],
                }}
                paint={{
                  "text-color": "white",
                }}
              />
            )}
          </Source>
        </>
      )}
    </>
  )
}
